@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v46/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RFD48TE63OOYKtrwEIJl1pynSEg.woff2) format('woff2');
    unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.intro-text h2 {
  color: white;
  /* text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000; */
  text-shadow: black 0px 0px 10px;
  font-size: 40px;
  letter-spacing: 1px;
  padding: 5px;
  margin:0;
  text-transform: none !important;
}

header .intro-text {
  text-align: left;
  padding-top: 150px;
  padding-bottom: 10px;
}

header .intro-text2 {
  font-family: 'Roboto';
  padding-top: 60px;
  padding-bottom: 100px;
  text-align: center;
}

.intro p {
  font-size: 25px;
  line-height: 40px;
}

.service-desc p {
  font-size: 18px;
}

.service-box{
  height: 450px;
}

.section-title.products{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

float-layout {
  padding: 5px 5px;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0;
}

.card-container {
  overflow: hidden;
}

.card {
  background-color: transparent;
  color: black;
  width: 100%;
  float: right;
}

.card-title {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
}

.card-desc {
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-family: 'Roboto';
}

/*add this it*/
.card-image {
  display: flex;
}
/*-------------*/

div.card-image img {
  width: 50%;
  height: auto;
}

.success {
  color: white;
}

/* Phone Devices Query */
@media only screen and (max-width: 37.5em) {
  div.card-image img {
    width: 100%;
    height: auto;
  }
  
  /*add this it*/
  .card-image {
     flex-direction: column;
  }
  /*----------------------*/

  .card {
    width: 100%;
    margin-top: -4px;
  }
}